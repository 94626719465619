@import "inc/_variables.scss";

body
{
    text-rendering: optimizeLegibility;
}

h1
{
    text-align: center;
    position: relative;
    padding-bottom: 1em;
    margin-bottom: 2em;
    text-transform: uppercase;

    &:after
    {
        content: "";
        position: absolute;
        border-bottom: $underlineHeight solid $accentBase;
        width: 100%;
        max-width: 200px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.wow
{
    visibility: hidden;
}

.btn
{
    border-width: 2px;
    text-transform: uppercase;
}

.btn-link
{
    border: 0;
    padding: 0;
}

input[type=file].form-control
{
    border: 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
}

.btn-options
{
    .btn
    {
        margin: 0 1em 1em 0;

        &:last-child
        {
            margin-right: 0;
        }
    }
}

.desktop-text-left,
.desktop-text-right,
.tablet-text-left,
.tablet-text-right
{
    text-align: center;
}

@media(min-width: $screen-sm-min)
{
    .tablet-text-left
    {
        text-align: left;
    }

    .tablet-text-right
    {
        text-align: right;
    }
}

@media(min-width: $screen-md-min)
{
    .desktop-text-left
    {
        text-align: left;
    }

    .desktop-text-right
    {
        text-align: right;
    }
}

.breadcrumb
{
    text-align: right;
    background-color: transparent;
    padding: 0;
}
